.cw-chips-navigation {
  max-width: 100%;
  overflow: auto;

  &.allow-overflow {
    overflow: visible;
  }

  &::-webkit-scrollbar {
    height: 3px;
  }

  .MuiChip-root {
    cursor: pointer;
  }

  .MuiBadge-badge {
    right: 3px !important;
  }
}