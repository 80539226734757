.cw-avatar-logo {
  border: 1px solid rgba(var(--color-dark-rgb), 0.2);
  position: relative;
  overflow: hidden;
  background-color: var(--color-light);
  flex-shrink: 0;

  &.no-border {
    border: none;
  }

  &.shape {
    &--round {
      border-radius: 50%;
    }
    &--square {
      border-radius: var(--cw-spacing-2);
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }

  &.editable {
    cursor: pointer;
  }

  .edit-overlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--color-dark-rgb), 0.3);

    opacity: 0;
  }

  &:hover {
    .edit-overlay {
      opacity: 1;
    }
  }

  &.small-font {
    font-size: var(--cw-spacing-4) !important;
  }

  &.size {
    &--tiny {
      height: var(--cw-spacing-8);
      width: var(--cw-spacing-8);
      font-size: var(--cw-spacing-3);
    }
    &--smaller {
      height: var(--cw-spacing-10);
      width: var(--cw-spacing-10);
      font-size: var(--cw-spacing-4);
    }
    &--small {
      height: var(--cw-spacing-12);
      width: var(--cw-spacing-12);
      font-size: var(--cw-spacing-5);
    }
    &--medium {
      height: var(--cw-spacing-24);
      width: var(--cw-spacing-24);
      font-size: var(--cw-spacing-10);
    }
    &--large {
      height: var(--cw-spacing-48);
      width: var(--cw-spacing-48);
      font-size: var(--cw-spacing-20);
    }
  }

  &.primary {
    background-color: var(--color-primary);
    color: var(--color-light);
  }
  &.secondary {
    background-color: var(--color-secondary);
    color: var(--text-color);
  }
  &.tertiary {
    background-color: var(--color-tertiary);
    color: var(--color-light);
  }
  &.success {
    background-color: var(--color-success);
    color: var(--color-light);
  }
  &.warning {
    background-color: var(--color-warning);
    color: var(--text-color);
  }
  &.error {
    background-color: var(--color-error);
    color: var(--color-light);
  }
  &.dark {
    background-color: var(--color-dark);
    color: var(--color-light);
  }
}