.cw-error-page {
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(-35deg, var(--color-primary), rgba(var(--color-primary-rgb), 0.7));

  padding-top: var(--cw-spacing-30);

  &.mobile, &.small-desktop {
    padding-top: var(--cw-spacing-10);
  }

  .logo {
    width: 40%;

    &.mobile {
      width: 50vw;;
    }
  }
}