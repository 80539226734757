.cw-socials-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  column-gap: var(--cw-spacing-3);
  align-items: center;
  padding: var(--cw-spacing-3);
  border-radius: var(--cw-spacing-3);
  width: 100%;
  font-size: var(--cw-spacing-4);
  font-weight: 500;
  position: relative;
  overflow: hidden;

  &.facebook {
    background-color: #1877F2;
    color: #FFFFFF;
  }
  &.google {
    background-color: #FFFFFF;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.168);
  }
  &.apple {
    background-color: #000000;
    color: #FFFFFF;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}