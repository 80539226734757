@use "sass:math";

:root {
  --border-light: 1px solid rgba(var(--color-light-rgb), 0.2);

  @for $i from 1 through 50 {
		--cw-spacing-#{$i}: #{math.div($i, 4)}rem;
	}

	--cw-box-shadow: 0 6px 10px 0px rgba(var(--color-dark-rgb), 0.08);
	--cw-box-shadow-heavy: 0 6px 10px 0px rgba(var(--color-dark-rgb), 0.16);
  --cw-border-color: rgba(var(--color-dark-rgb), 0.2);

  * {
    &::-webkit-scrollbar {
      height: 5px;
      width: 8px;
      background-color: #ebebeb;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-background-darker);
      border-radius: 8px;
    }
  }
}

body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

.pointer {
  cursor: pointer;
}

.cw-link {
  color: var(--color-primary);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.absolute-full-page {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--color-background-lighter) inset !important;
}

.img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}

.strike-through {
  text-decoration: line-through;
  color: var(--color-text-disabled);
  font-style: italic;
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cw-tabs-badge {
  .MuiBadge-badge {
    top: -10px !important;
    right: -6px !important;
  }
}
.capitalize {
  text-transform: capitalize;
}
